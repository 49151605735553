import {
  IonButton,
  IonContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonToolbar,
  IonCardTitle,
  IonCardSubtitle,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { Project } from "../../models";
import {
  TimesheetSummaryCard,
  CashflowSummaryCard,
  ContactCard,
  ContactModal,
  BreakdownCard,
  FinancialStatementCard,
  Header,
  ProjectModal,
} from "..";
import { LabourCosts, ProjectRevenue, useProjects, useProjectFinances } from "../../hooks";
import {
  arrowForwardOutline,
  stopwatchOutline,
  eyeOutline,
} from "ionicons/icons";
import { useLocation, useHistory } from "react-router-dom";

interface ProjectSummaryTabInterface {
  project: Project | undefined;
  labour: LabourCosts | undefined;
  revenue: ProjectRevenue | undefined;
}

export const ProjectSummaryTab: React.FC<ProjectSummaryTabInterface> = (
  props
) => {
  // Contavt state
  const [contactId, setContactId] = useState<string>();
  const [newProject, setNewProject] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(0);
  const [activeProject, setActiveProject] = useState<Project | null | undefined>(null);
  const location = useLocation();
  const history = useHistory();
  const {getProjectById, deleteProjectById, getLabourByProjectId, getRevenueByProjectId} = useProjects();
  const [project, setProject] = useState<Project | null | undefined>(props.project);
  const [labour, setLabour] = useState<LabourCosts | undefined>(props.labour);
  const [revenue, setRevenue] = useState<ProjectRevenue | undefined>(props.revenue); 
  const [showUnapproved, setShowUnapproved] = useState<boolean>(false);

  const { activeProjectId, setActiveProjectId } = useProjectFinances();

  let doRefresh = () => {
    //setPageRefresh(Math.floor(Math.random() * 100));
    //console.log(activeProject);
    //history.push("../jobs/" + activeProject?.id);
    window.location.reload();
  };

  useIonViewWillEnter(() => {
    let aurl = window.location.href.split('/');
    let sprojectId = aurl[aurl.length - 1];
    console.log('will enter 2: ' + project?.id);
    console.log(window.location.href);
    console.log(sprojectId);
    console.log(props.project);
    console.log(project!);
    console.log('lololol');
    //console.log(project);
    //console.log(activeProject);
    if (sprojectId) {
      //loadProject(project.id);
      getProjectById(sprojectId).then((o) => {
        setProject(o);  
      });
      getLabourByProjectId(sprojectId).then((o) => {
        setLabour(o);
      });
      getRevenueByProjectId(sprojectId).then((o) => {
        setRevenue(o);
      })
    } else {
      console.log('testando');
      console.log(props.project!);
      //loadProject(project.id);
      // getProjectById(activeProject!.id).then((o) => {
      //   setProject(o);  
      // });
      // getLabourByProjectId(activeProject!.id).then((o) => {
      //   setLabour(o);
      // });
      // getRevenueByProjectId(activeProject!.id).then((o) => {
      //   setRevenue(o);
      // });
    }
  });

  useEffect(() => {
    console.log('lululu');
    console.log(project);
    console.log(activeProject);
    if (project) {
      //loadProject(project.id);
      getProjectById(project.id).then((o) => {
        setProject(o);  
      });
      getLabourByProjectId(project.id).then((o) => {
        setLabour(o);
      });
      getRevenueByProjectId(project.id).then((o) => {
        setRevenue(o);
      })
    } else {
      console.log('testando');
      console.log(props.project!);
      //loadProject(project.id);
      // getProjectById(activeProject!.id).then((o) => {
      //   setProject(o);  
      // });
      // getLabourByProjectId(activeProject!.id).then((o) => {
      //   setLabour(o);
      // });
      // getRevenueByProjectId(activeProject!.id).then((o) => {
      //   setRevenue(o);
      // });
    }
  }, []);

  return (
    <IonContent>
      {/* Page header */}
      <Header
        title={
          "" +
          project?.code?.toUpperCase() +
          project?.number?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        }
      />
      
      {/* Project summary */}
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeSm="12">
            <IonCard style={{ height: "100%" }}>
              <IonCardHeader>
                <IonToolbar color="translucent">
                  <IonCardTitle>{project?.code}</IonCardTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={() => {
                      console.log(project?.code);
                      setActiveProject(project)
                    }}>
                      <IonIcon slot="icon-only" icon={eyeOutline} />
                    </IonButton>
                  </IonButtons>
                  <IonCardSubtitle>Job Sumary</IonCardSubtitle>
                  <IonCardSubtitle>{project?.supervisor?.name}</IonCardSubtitle>
                  {project?.address &&
                    <IonCardSubtitle>Address: {project?.address?.address1} {project?.address?.address2}, {project?.address?.city},  {project?.address?.country}, {project?.address?.postal}</IonCardSubtitle>
                  }
                </IonToolbar>
              </IonCardHeader>
            </IonCard>
            
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeSm="4">
            {/* Builder contact card */}
            <ContactCard
              contact={project?.customer !== null ? project?.customer : undefined}
              onView={() => setContactId(project?.customer?.id)}
              supervisor={project?.supervisor}
            />
          </IonCol>
          <IonCol size="12" sizeSm="4">
            {/* Labour summary card */}
            <TimesheetSummaryCard labour={labour} showUnapproved={showUnapproved} />
          </IonCol>
          <IonCol size="12" sizeSm="4">
            {/* Cashflow summary card */}
            <CashflowSummaryCard revenue={revenue} />
          </IonCol>
        </IonRow>

        {/* Project breakdown */}
        <IonRow>
          <IonCol size="12" sizeSm="7">
            <BreakdownCard showUnapproved={showUnapproved} labour={labour} revenue={revenue} />
          </IonCol>
          <IonCol size="12" sizeSm="5">
            <FinancialStatementCard
              labour={labour}
              revenue={revenue}
              showUnapproved={showUnapproved}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <ContactModal id={contactId} onDismiss={() => setContactId(undefined)} />

      <ProjectModal
        projectId={activeProject?.id}
        newProject={newProject}
        onDelete={() => {
          if (activeProject?.id) {
            deleteProjectById(activeProject?.id);
          }
          history.push("../jobs");
          setActiveProject(undefined);
          // newProject ? setNewProject(false) : setRefreshProjects(true);
        }}
        onDismiss={function() {
          setActiveProject(undefined);
          // setRefreshProjects(true);
          // setNewProject(false);
        }}
        onSave={(function() {
          //alert('abcde');
          doRefresh();
        })}
      />

    </IonContent>
  );
};
