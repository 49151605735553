import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { eyeOutline } from "ionicons/icons";
import { AuthUser, Contact } from "../../models";
import { EmailButton, PhoneButton } from "../";
import { Link } from "react-router-dom";

interface ContactCardProps {
  contact: Contact | undefined;
  onView: () => void;
  supervisor?: Contact | null | undefined;
}

export const ContactCard: React.FC<ContactCardProps> = (props) => {
  return (
    <>
      {props.contact && (
        <IonCard style={{ height: "100%" }}>
          <IonCardHeader>
            <IonToolbar color="translucent">
              <IonCardTitle>{props.contact.name}</IonCardTitle>
              <IonButtons slot="end">
                {/* Open modal */}
                <IonButton onClick={() => props.onView()}>
                  <IonIcon slot="icon-only" icon={eyeOutline} />
                </IonButton>
              </IonButtons>
              <IonCardSubtitle>{props.contact.type}</IonCardSubtitle>
            </IonToolbar>
          </IonCardHeader>
          <IonCardContent>
            <IonList lines="none">
              <IonItem>
                <IonLabel>Phone</IonLabel>
                <IonText slot="end">
                  <PhoneButton tel={props.contact.phone} />
                </IonText>
              </IonItem>
              <IonItem>
                <IonLabel>Email</IonLabel>
                <IonText slot="end">
                  {/* <EmailButton email={props.contact.email}> */}
                  <Link to={props.contact.email || ""}>{props.contact.email || ""}</Link>
                </IonText>
              </IonItem>
              {props.supervisor && 
                <IonItem>
                  <IonLabel>Supervisor</IonLabel>
                  <IonText slot="end">
                    <Link to={props.supervisor.email || ""}>{props.supervisor.email || ""}</Link>
                  </IonText>
                </IonItem>
              }
            </IonList>
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
};
