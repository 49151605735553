import { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, refresh, trashBinOutline } from "ionicons/icons";
import { AddressInput, StringInput, SelectInput, BuilderInput } from "..";
import { useContact, useUser } from "../../hooks";
import { ContactType } from "../../models";

export interface ContactModalInterface {
  id: null | string | undefined;
  onDelete?: () => void;
  onDismiss: () => void;
}

export const ContactModal: React.FC<ContactModalInterface> = (props) => {
  // User
  const { security } = useUser();
  const [canEdit, setCanEdit] = useState(false);

  // Open state
  const [isOpen, setIsOpen] = useState(false);
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const [errorAddress, setErrorAddress] = useState<string>();

  const [formError, setFormError] = useState<string>("");

  // Hook into contact info
  const { setActiveId, activeContact, setActiveContact, errors, saveContact } =
    useContact();

  // Save button trigger, if new address save else just trigger save
  const handleSaveClick = () => {
    activeContact?.type === ContactType.BUILDER
      ? setUpdatingAddress(true)
      : onContactSave();
  };

  // Save contact
  const onContactSave = () => {
    saveContact();
    setUpdatingAddress(false);
    props.onDismiss();
  };

  const getRefreshedFormError = () => {
    if (!activeContact?.name || !activeContact?.email || !validateEmail(activeContact.email) || !activeContact?.phone || (activeContact.type === ContactType.BUILDER && !activeContact?.code) || errorAddress) {
      return "Please fill the mandatory fields and valid e-mail address.";
    } else {
      return "";
    }
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const refreshFormError = async () => {
    setFormError(getRefreshedFormError());
  };

  // Trigger the save
  useEffect(() => {
    if (updatingAddress && activeContact?.addressId !== "new") onContactSave();
  }, [updatingAddress, activeContact?.addressId]);

  // Triggers
  useEffect(() => {
    setActiveId(props.id !== null ? props.id : undefined);
    props.id ? setIsOpen(true) : setIsOpen(false);
    refreshFormError();
  }, [props.id]);

  useEffect(() => {
    if (activeContact) {
      refreshFormError();
    }
  }, [activeContact, errorAddress]);

  // Securtiy
  useEffect(() => {
    security.isAdmin || security.isOffice
      ? setCanEdit(true)
      : setCanEdit(false);
  }, [security]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onDismiss()}>
      {/* Toolbar/title */}
      {activeContact && (
        <IonToolbar>
          <IonTitle>{activeContact.name}</IonTitle>
          <IonButtons slot="end">
            {props.onDelete && (
              <IonButton onClick={() => props.onDelete!()}>
                <IonIcon slot="icon-only" icon={trashBinOutline} />
              </IonButton>
            )}
            <IonButton onClick={() => props.onDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      )}

      {/* Display content if contact set */}
      {activeContact && (
        <IonContent>
          {/* Contact Details */}
          <IonList>
            <IonListHeader>
              <IonText color="medium">Details</IonText>
            </IonListHeader>
            {formError && 
              <IonListHeader>
                <IonText color="danger">{formError}</IonText>
              </IonListHeader>
            }
            {/* Name */}
            <StringInput
              label="Name *"
              value={activeContact.name}
              canEdit={canEdit}
              onChange={(value) =>
                setActiveContact({
                  ...activeContact,
                  name: value,
                })
              }
            />

            {/* Email */}
            <StringInput
              label="Email *"
              value={activeContact.email}
              canEdit={canEdit}
              onChange={(value) =>
                setActiveContact({
                  ...activeContact,
                  email: value,
                })
              }
              error={{
                error: errors?.email,
                msg: "This email is already in use.",
              }}
            />

            {/* phone */}
            <StringInput
              label="Phone Number *"
              value={activeContact.phone}
              canEdit={canEdit}
              onChange={(value) =>
                setActiveContact({
                  ...activeContact,
                  phone: value,
                })
              }
              error={{
                error: errors?.phone,
                msg: "This phone number already exists for a contact.",
              }}
            />

            {/* Type */}
            <SelectInput
              label="Type"
              value={activeContact.type}
              options={Object.keys(ContactType)}
              canEdit={canEdit}
              onChange={(value) => {
                setActiveContact({
                  ...activeContact,
                  type: value,
                });
              }}
            />

            {/* code */}
            {activeContact.type === ContactType.BUILDER && (
              <StringInput
                label="Prefix *"
                value={activeContact.code}
                canEdit={canEdit}
                onChange={(value) => {
                  let normalized_value = value.replaceAll(/[^A-Za-z]/g, '').substring(0, 3);
                  setActiveContact({
                    ...activeContact,
                    code: normalized_value.toUpperCase(),
                  });
                }}
                error={{
                  error: errors?.code,
                  msg: "This code is already in use.",
                }}
              />
            )}
          </IonList>

          {/* Popup for builder */}
          {activeContact.type === ContactType.SUPERVISOR && (
            <BuilderInput
              id={activeContact.builderId}
              onChange={(id) =>
                setActiveContact({ ...activeContact, builderId: id })
              }
              error={{
                error: errors?.supervisor,
                msg: "Builder is required for a supervisor.",
              }}
            />
          )}

          {/* Builder address // activeContact && false && activeContact.type !== ContactType.BUILDER && ( */}
          {/* {false && 
            <AddressInput
              id={activeContact.addressId !== null ? activeContact.addressId : undefined}
              saveAddress={updatingAddress}
              canEdit={canEdit}
              onChange={(id) =>
                setActiveContact({ ...activeContact, addressId: id })
              }
              onErrorAddress={(s) => setErrorAddress(s)}
            />
          )} */}

          {canEdit && (
            <>
              {formError && 
                <IonListHeader>
                  <IonText color="danger">{formError}</IonText>
                </IonListHeader>
              }
              <IonButton
                disabled={
                  formError !== null && formError !== undefined && formError !== ''
                }
                onClick={() => handleSaveClick()}
                expand="block"
              >
                Save
              </IonButton>
            </>
          )}
        </IonContent>
      )}
    </IonModal>
  );
};
